import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Button,
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    FormControl,
    FormControlLabel,
    FormLabel,
    Grid,
    IconButton,
    Radio,
    RadioGroup,
    TextField,
    Tooltip,
    Typography,
    makeStyles,
} from "@material-ui/core";
import {
    AddOutlined,
    Close,
    InsertDriveFileOutlined,
    KeyboardArrowDownOutlined,
} from "@material-ui/icons";
import { Alert } from "@material-ui/lab";
import { ExpandCircleDown } from "@mui/icons-material";
import { ChangeEvent, FormEvent, useContext, useEffect, useState } from "react";
import { PaymentService } from "../../../../services/PaymentService";
import { S3Service } from "../../../../services/S3Service";
import { Autocomplete } from "../../../Autocomplete/Autocomplete";
import { awsSettings } from "../../../constants";
import UserContext from "../../../UserContext";
import { Payment } from "../../types";
import { PaymentContext } from "../Payments";
import SelectedHeadsNAmounts from "../SelectedHeadsNAmounts";

const initialState: Payment = {
    status: "",
    client: "",
    clientType: "",
    descriptionTags: "",
    heads: [],
    name: "",
    bank: "",
    type: "",
    description: "",
};

export type PaymentHeadResponse = {
    id: number;
    amount: number;
    isAmountEditable: boolean;
    name: string;
    control: number;
};

const initialFilter = { clientType: "", client: "" };
const initialFeedback = {
    loading: false,
    hidden: true,
    severity: "success",
    message: "",
};

export const CreatePayment = () => {
    const [expanded, setExpanded] = useState(false);
    const [count, setCount] = useState(0);
    const [name, setName] = useState("");
    const [description, setDescription] = useState("");
    const [payment, setPayment] = useState<Payment>(initialState);
    const [feedback, setFeedback] = useState(initialFeedback);
    const [paymentHeads, setPaymentHeads] = useState<PaymentHeadResponse[]>([]);
    const [descriptionTags, setDescriptionTags] = useState<any[]>([]);
    const [bank, setBank] = useState(null);
    const [type, setType] = useState("");
    const [filter, setFilter] = useState(initialFilter);
    const [clearFilters, setClearFilters] = useState(false);
    const [file, setFile] = useState<{
        file: File;
        src: string;
        openPreview: boolean;
    } | null>(null);
    const [lastBalance, setLastBalance] = useState<any>();
    const [showResponseDetails, setShowResponseDetails] = useState(false);
    const [response, setResponse] = useState<{
        successes: { id: number; message: string }[];
        failures: { id: number; message: string }[];
    }>({ successes: [], failures: [] });
    //
    const { user } = useContext(UserContext);
    const {
        refetchCount: counter,
        setRefetchCount: setCounter,
        rowToUpdate: paymentToUpdate,
        setRowToUpdate: setPaymentToUpdate,
    } = useContext(PaymentContext);
    const classes = useStyles();

    //
    function handleAccordionExpansion() {
        setExpanded(!expanded);

        if (expanded) {
            setPayment(() => initialState);
            setFeedback(initialFeedback);
            setPaymentHeads([]);
            setPaymentToUpdate(null);
            setFilter(() => ({ clientType: "", client: "" }));
        }
    }

    const handleFile = (ev: ChangeEvent<HTMLInputElement>) => {
        if (ev.target.files && ev.target.files.length > 0) {
            const f = ev.target.files[0];

            // This number is the number of bytes in 1MB
            // if (f.size > 1048576) {
            //     setFeedback({
            //         status: "error",
            //         message: "Uploaded file must be less than 1MB",
            //     });
            //     return;
            // } else {
            //     setFeedback({ status: "idle", message: "" });
            // }

            setFile({
                file: f,
                src: URL.createObjectURL(f),
                openPreview: false,
            });
        }
    };

    // const getLastBalance = async () => {
    //     const [data, err] = await PaymentService.getLastBalance(1, 1, {
    //         client: filter.client ? filter.client : null,
    //     });
    //     if (data) {
    //         setLastBalance(data?.balance);
    //         setCount(count + 1);
    //     }
    // };
    const getLastBalance = async () => {
        const [data, err] = await PaymentService.getLastBalance(1, 1, {
            client: filter.client || null, // Cleaner shorthand
        });

        if (data) {
            setLastBalance(data.balance || 0); // Reset to 0 if no balance found
        } else {
            setLastBalance(0); // Ensure old balance is cleared if no data
        }
    };

    const handleFilterClear = () => {
        setClearFilters(() => !clearFilters);
        setBank(() => null);
        setName(() => "");
        setDescription(() => "");
        setPayment(() => initialState);
        setFilter(() => initialFilter);
        setPaymentHeads(() => []);
        setDescriptionTags(() => []);
        setType(() => "");
        setFile(() => null);
        setLastBalance(() => "");
    };

    const handleSubmit = async (ev: FormEvent<any>) => {
        ev.preventDefault();

        setFeedback({ ...feedback, loading: true });

        const heads = paymentHeads.map(({ id, name, amount }) => ({
            id,
            name,
            amount,
        }));

        let handler = () =>
            PaymentService.createPayment({
                ...payment,
                heads,
                name: name,
                descriptionTags,
                status: payment.status,
                client: filter.client,
                clientType: filter.clientType,
                bank: bank as any,
                type: type,
                description: description as any,
            });

        const [data, err] = await handler();

        if (data && data?.payment?.id) {
            const key = `${awsSettings.paymentDir}/${data.payment.id}`;
            // if (file) {
            //     await S3Service.uploadToS3(key, file.file);
            // }
            if (file) {
                const extension = file.file.type.split("/")[1]; // e.g., 'jpg', 'png', 'pdf'
                const key = `${awsSettings.paymentDir}/${data.payment.id}.${extension}`; // Add extension
                await S3Service.uploadToS3(key, file.file);
            }
        }

        if (data) {
            setFeedback({
                loading: false,
                hidden: false,
                severity: "success",
                message: data.message || "Payment created successfully",
            });

            setTimeout(() => {
                setFeedback(initialFeedback);
                setCount((c) => c + 1);
            }, 1000);
            setCounter(counter + 1);
        } else {
            if (data?.successes?.length || data?.failures?.length) {
                setResponse({
                    successes: data?.successes,
                    failures: data?.failures,
                });

                setFeedback({
                    loading: false,
                    hidden: false,
                    severity: "error",
                    message: err || "Failed to create the payment",
                });
            }

            setCounter(counter + 1);

            setFeedback({
                loading: false,
                hidden: false,
                severity: "error",
                message: err || "Failed to create the payment head",
            });
        }
    };

    useEffect(() => {
        if (filter.client) {
            getLastBalance();
        }
    }, [filter.client]);

    useEffect(() => {
        if (!filter.client) {
            setLastBalance(0);
        }
    }, [filter.client]);

    return (
        <Accordion expanded={expanded} onClick={handleAccordionExpansion}>
            <AccordionSummary expandIcon={<KeyboardArrowDownOutlined />}>
                <Typography
                    variant="h5"
                    style={{ textTransform: "capitalize" }}
                >
                    Payment
                </Typography>
            </AccordionSummary>

            <AccordionDetails onClick={(e) => e.stopPropagation()}>
                <Grid
                    container
                    spacing={2}
                    component="form"
                    alignItems="center"
                    onSubmit={handleSubmit}
                >
                    <Grid
                        item
                        xs={12}
                        component="section"
                        hidden={feedback.hidden}
                        style={{ paddingBottom: "1rem" }}
                    >
                        <Alert
                            severity={feedback.severity as any}
                            style={{ width: "100%" }}
                        >
                            {feedback.message}
                        </Alert>
                    </Grid>

                    <Grid item xs={12}>
                        <FormControl required disabled={type == "adjustment"}>
                            <FormLabel>Transaction Type</FormLabel>

                            <RadioGroup
                                row
                                name="transactionType"
                                value={payment.status}
                                onChange={(ev) =>
                                    setPayment({
                                        ...payment,
                                        status: ev.target.value,
                                    })
                                }
                            >
                                <FormControlLabel
                                    label="Credit"
                                    value="credit"
                                    control={<Radio />}
                                />

                                <FormControlLabel
                                    label="Debit"
                                    value="debit"
                                    control={<Radio />}
                                />
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                            name="name"
                            value={name}
                            onChange={(ev: ChangeEvent<HTMLInputElement>) =>
                                setName(ev.target.value)
                            }
                            label="Name"
                            variant="outlined"
                            fullWidth
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            label="Client Type"
                            api="/clientType/get-All"
                            labelKey="name"
                            setOutput={(opt) => {
                                setFilter({
                                    ...filter,
                                    clientType: opt?.id ?? "",
                                });
                            }}
                            textFieldProps={{ variant: "outlined" }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            label="Client"
                            api="/employee/all-employees"
                            labelKey="name"
                            setOutput={(opt) => {
                                setFilter({
                                    ...filter,
                                    client: opt?.baseUser?.id ?? "",
                                });
                            }}
                            textFieldProps={{ variant: "outlined" }}
                            apiParams={{ clientType: filter.clientType }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            api="/payment/head"
                            setOutput={(heads: PaymentHeadResponse[]) => {
                                setPaymentHeads(heads);
                            }}
                            multiple
                            labelKey="name"
                            label="Head"
                            textFieldProps={{
                                variant: "outlined",
                            }}
                            defaultValue={paymentHeads}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            api="/description-tags/get-All"
                            setOutput={(des: any) => {
                                setDescriptionTags(
                                    des.map((item: any) => item)
                                );
                            }}
                            labelKey="name"
                            label="Description Tags"
                            textFieldProps={{
                                variant: "outlined",
                            }}
                            multiple
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            api="/payment/method/get-All"
                            setOutput={(bank: any) => {
                                if (!bank) return;
                                setBank(bank.id);
                            }}
                            labelKey="name"
                            label="Bank"
                            textFieldProps={{
                                variant: "outlined",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <Autocomplete
                            api="/payment/get-status"
                            setOutput={(des: any) => {
                                if (!des) return;
                                setType(des.status);
                                if (des.status == "adjustment") {
                                    setPayment((curr) => {
                                        return { ...curr, status: "credit" };
                                    });
                                }
                            }}
                            labelKey="label"
                            label="Status"
                            textFieldProps={{
                                variant: "outlined",
                            }}
                            clear={clearFilters}
                        />
                    </Grid>

                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                            name="description"
                            value={description}
                            onChange={(ev: any) =>
                                setDescription(ev.target.value)
                            }
                            label="Description"
                            variant="outlined"
                            fullWidth
                            minRows={4}
                        />
                    </Grid>
                    <Grid item xs={12} md={6} lg={4}>
                        <TextField
                            label="Balance"
                            value={lastBalance} // bind the value to lastBalance state
                            disabled={true} // make the TextField read-only
                            fullWidth // optional, for full width
                            variant="outlined"
                            InputLabelProps={{ shrink: true }} // optional, set the variant to outlined
                        />
                    </Grid>

                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        style={{
                            display: "flex",
                            gap: "4px",
                            alignItems: "center",
                        }}
                    >
                        <Tooltip title={file ? file.file.name : ""}>
                            <Typography variant="body2">
                                {file
                                    ? file.file.name.length > 10
                                        ? file.file.name.slice(0, 10) + " ..."
                                        : file.file.name
                                    : "Upload Receipt"}
                            </Typography>
                        </Tooltip>
                        <IconButton size="small" component="label">
                            <InsertDriveFileOutlined />
                            <input
                                hidden
                                type="file"
                                onChange={handleFile}
                                accept="image/*,application/pdf"
                            />
                        </IconButton>

                        {file ? (
                            <IconButton
                                size="small"
                                onClick={() =>
                                    setFile({ ...file, openPreview: true })
                                }
                            >
                                <ExpandCircleDown />
                            </IconButton>
                        ) : null}

                        {file ? (
                            <IconButton
                                onClick={() => setFile(null)}
                                size="small"
                            >
                                <Close />
                            </IconButton>
                        ) : null}
                    </Grid>

                    <Dialog
                        fullWidth
                        open={Boolean(file?.openPreview)}
                        onClose={() =>
                            setFile({ ...file, openPreview: false } as any)
                        }
                    >
                        <DialogTitle>Preview</DialogTitle>

                        <DialogContent>
                            {file && (
                                <img
                                    style={{ borderRadius: "4px" }}
                                    width="100%"
                                    alt="Receipt preview"
                                    src={file.src}
                                />
                            )}
                        </DialogContent>

                        <DialogActions>
                            <Button
                                onClick={() =>
                                    setFile({
                                        ...file,
                                        openPreview: false,
                                    } as any)
                                }
                            >
                                Close
                            </Button>
                        </DialogActions>
                    </Dialog>

                    {paymentHeads.length ? (
                        <Grid item xs={12}>
                            <SelectedHeadsNAmounts
                                heads={paymentHeads}
                                setHeads={setPaymentHeads}
                            />
                        </Grid>
                    ) : null}

                    <Grid
                        item
                        xs={12}
                        md={6}
                        lg={4}
                        style={{ padding: "0 auto" }}
                    >
                        <Button
                            fullWidth
                            type="submit"
                            variant="outlined"
                            color="primary"
                            size="large"
                            style={{ padding: "11px 0" }}
                            disabled={feedback.loading}
                            endIcon={
                                feedback.loading ? (
                                    <CircularProgress
                                        color="primary"
                                        size="1rem"
                                    />
                                ) : (
                                    <AddOutlined />
                                )
                            }
                        >
                            Create Payment
                        </Button>
                    </Grid>
                </Grid>
            </AccordionDetails>
        </Accordion>
    );
};

const useStyles = makeStyles((theme) => ({
    transactionBtn: {
        height: "auto",
        width: "auto",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        borderRadius: theme.spacing(99),
    },
    transactionTypeBtnContainer: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        gap: theme.spacing(1),
    },
}));
